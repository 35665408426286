
export const API_URL = "https://ghi-report-api-dev.mystrand.org/api"
// export const API_URL = "http://localhost:8000/api"

export const APP_ENV = "client"
// export const APP_ENV = "reviewer"

//development X_API_KEY
export const X_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiSkhIIFRlc3QgVXNlciIsImVtYWlsIjoiamhoZ2hpYXBpdXNlckBzdHJhbmRscy5jb20iLCJpYXQiOjE2OTE5MjgxOTd9.-iV1HDrMpHAQ51IpkvjvmWiotlhbfEl6xOGU1Kvi5Qc';

export const GET_SINGLE_REPORT_URL = (APP_ENV === "client") ?
    '/zoho/getSingleReport' :
    '/zoho/getSingleDraftReport';


const isUserClient = () => {

    return (APP_ENV === "client") ? true : false;
}

export default {
    isUserClient
}
 
export const ENV = "development"
